import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Welcome.css';
import '../index.css';
import BannerImage from '../assets/banner.jpg';
import japTrackingImg from '../assets/jap-tracking.png';
import devotionalContentImg from '../assets/devotional-content.png';
import pujaSamagriImg from '../assets/puja_samagri.jpg';
import levelsRewardsImg from '../assets/levels-rewards.png';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import { Tooltip, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Welcome() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <div className="welcome">
      {/* Hero Section */}
      <div className="hero-section">
        <img src={BannerImage} alt="BhaktiVerse Banner" className="banner-image" />
        <div className="hero-content">
          <h1 className="tagline">{t('explore_universe')}</h1>
          <p className="description">{t('description')}</p>
          <div className="buttons">
            <a href="/download" className="btn download-btn">{t('download_app')}</a>
            <ScrollLink to="features" smooth={true} duration={500} className="btn learn-more-btn">
              {t('learn_more')}
            </ScrollLink>
          </div>
        </div>
      </div>

      {/* Mission and Vision Section */}
      <div className="mission-vision">
        <div className="mission">
          <h2>{t('our_mission')}</h2>
          <p>{t('mission_statement')}</p>
        </div>
        <div className="vision">
          <h2>{t('our_vision')}</h2>
          <p>{t('vision_statement')}</p>
        </div>
      </div>

      {/* Features Section */}
      <div className="features-preview" id="features">
        <h2>{t('explore_bhaktiverse')}</h2>
        <div className="features-container">

           {/* Feature: Devotional Content */}
           <div className="feature-item">
            <RouterLink to="/devotional-content">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <img src={devotionalContentImg} alt="Devotional Content" />
                <h3>{t('devotional_content')}</h3>
                <p>{t('devotional_content_desc')}</p>
              </motion.div>
            </RouterLink>
          </div>

          {/* Feature: Puja Samagri */}
          <div className="feature-item">
            <RouterLink to="/puja-samagri">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <img src={pujaSamagriImg} alt="Puja Samagri" />
                <h3>{t('puja_samagri')}</h3>
                <p>{t('puja_samagri_desc')}</p>
              </motion.div>
            </RouterLink>
          </div>
          
          {/* Feature: Jap Tracking */}
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {t('app_version_only')}
                </Typography>
                <Link href="/download" color="inherit" underline="always">
                  {t('download_link')}
                </Link>
              </React.Fragment>
            }
            enterTouchDelay={0}
            leaveTouchDelay={1500}
            arrow
          >
            <div className="feature-item">
              <img src={japTrackingImg} alt="Jap Tracking" />
              <h3>{t('jap_tracking')}</h3>
              <p>{t('jap_tracking_desc')}</p>
            </div>
          </Tooltip>

          {/* Feature: Levels and Rewards */}
          <Tooltip
            title={t('coming_soon')}
            enterTouchDelay={0}
            leaveTouchDelay={1500}
            arrow
          >
            <div className="feature-item">
              <img src={levelsRewardsImg} alt="Levels and Rewards" />
              <h3>{t('levels_rewards')}</h3>
              <p>{t('levels_rewards_desc')}</p>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Welcome;