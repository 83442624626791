// src/components/Download.js
import React from 'react';
import './Download.css'; // Create this file for styles
// import download from '../assets/download.jpg'; 

function Download() {
  return (
    <div className="download">
      <h2>Download BhaktiVerse App</h2>
      <p>Available on: 10/10/2024</p>
      <ul>
        <li><a href="#">Google Play Store</a></li>
        <li><a href="#">Apple App Store</a></li>
      </ul>
    </div>
  );
}

export default Download;