import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { Card, CardContent, CardMedia, Typography, Grid, IconButton, Box, Container, Button, Slider } from '@mui/material';
import { PlayArrow, Pause, Stop, Add, Remove, ChevronLeft, FastForward, FastRewind } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { db, storage } from '../firebase-config';
import { styled } from '@mui/system';

// Move function definitions above any calls
const formatContent = (content) => {
  return content.split('\\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const staggerAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: { delay: index * 0.2, duration: 0.6, ease: "easeOut" }
  })
};

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, #f3e5f5, #e1bee7)',
  borderRadius: '15px',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  maxHeight: '60vh',
  overflowY: 'auto',
  paddingRight: '15px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const DevotionalContentPage = () => {
  const [deities, setDeities] = useState([]);
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [fontSize, setFontSize] = useState(18);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio());
  const [visibleLines, setVisibleLines] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);

  const contentPages = selectedDeity ? 
    formatContent(selectedDeity.contentHindi).map((hindiLine, index) => ({
      hindi: hindiLine,
      english: formatContent(selectedDeity.contentEnglish)[index] || ''
    })) : [];

  useEffect(() => {
    fetchDeities();

    // Handle time update for audio

    audio.addEventListener('timeupdate', () => {
      setCurrentTime(audio.currentTime);
    });

    // Get audio duration when loaded
    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });

    return () => {
      audio.removeEventListener('timeupdate', () => {});
      audio.removeEventListener('loadedmetadata', () => {});
    };
  }, []);

  const fetchDeities = async () => {
    const querySnapshot = await getDocs(collection(db, 'deities'));
    const deitiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setDeities(deitiesData);
  };

  const selectDeity = async (deity) => {
    setSelectedDeity(deity);
    setVisibleLines(0); // Reset line visibility
    if (deity.audioUrl) {
      const audioUrl = await getDownloadURL(ref(storage, deity.audioUrl));
      audio.src = audioUrl;
    }
  };

  const toggleAudio = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const changeFontSize = (change) => {
    setFontSize(prevSize => Math.min(Math.max(prevSize + change, 12), 32));
  };

  const changeSpeed = (rate) => {
    audio.playbackRate = rate;
    setPlaybackRate(rate);
  };

  const handleSeek = (event, newValue) => {
    audio.currentTime = newValue;
    setCurrentTime(newValue);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  };

  return (
    <Container maxWidth="lg" className="page-content" sx={{ py: 4 }}>
      <Typography variant="h3" gutterBottom align="center" sx={{ color: '#4a148c', fontWeight: 'bold' }}>
        भक्ति लोक (Bhakti Lok)
      </Typography>

      {!selectedDeity ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Grid container spacing={3}>
            {deities.map((deity) => (
              <Grid item xs={6} sm={4} md={3} key={deity.id}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <StyledCard onClick={() => selectDeity(deity)} sx={{ cursor: 'pointer' }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={deity.imageUrl}
                      alt={deity.name}
                      sx={{ objectFit: 'cover' }}
                    />
                    <CardContent>
                      <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>{deity.name}</Typography>
                    </CardContent>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Button 
            onClick={() => setSelectedDeity(null)} 
            startIcon={<ChevronLeft />}
            sx={{ mb: 2, color: '#4a148c' }}
          >
            Back to Deities
          </Button>
          <Grid container spacing={3} alignItems="center"> {/* Added alignment center */}
            <Grid item xs={12} md={5}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <StyledCard>
                  <CardMedia
                    component="img"
                    height="400"
                    image={selectedDeity.imageUrl}
                    alt={selectedDeity.name}
                    sx={{ objectFit: 'cover' }}
                  />
                  <CardContent>
                    <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', color: '#4a148c' }}>{selectedDeity.name}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <IconButton onClick={toggleAudio} sx={{ color: '#4a148c' }}>
                        {isPlaying ? <Pause /> : <PlayArrow />}
                      </IconButton>
                      <IconButton onClick={() => changeFontSize(2)} sx={{ color: '#4a148c' }}>
                        <Add />
                      </IconButton>
                      <IconButton onClick={() => changeFontSize(-2)} sx={{ color: '#4a148c' }}>
                        <Remove />
                      </IconButton>
                    </Box>
                     {/* Playback Speed Control */}
                     <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button onClick={() => changeSpeed(0.5)}>0.5x</Button>
                      <Button onClick={() => changeSpeed(1)}>1x</Button>
                      <Button onClick={() => changeSpeed(1.5)}>1.5x</Button>
                      <Button onClick={() => changeSpeed(2)}>2x</Button>
                    </Box>

                    {/* Audio Progress Slider */}
                    <Slider
                      value={currentTime}
                      min={0}
                      max={duration}
                      step={1}
                      onChange={handleSeek}
                      sx={{ color: '#4a148c', mt: 2 }}
                    />
                    <Typography align="center">
                      {formatTime(currentTime)} / {formatTime(duration)}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={7}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: 1, textAlign: 'center' }}> {/* Center-align content */}
                    <ContentBox>
                      <AnimatePresence>
                        {contentPages.length > 0 && (
                          <Box>
                            {contentPages.slice(0, contentPages.length).map((page, index) => (
                              <motion.div
                                key={index}
                                custom={index}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                variants={staggerAnimation}  // Apply staggered animation
                                sx={{ mb: 2 }}
                              >
                                <Typography 
                                  variant="body1" 
                                  sx={{ 
                                    fontSize: `${fontSize}px`,
                                    color: '#d32f2f', // Hindi in red
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {page.hindi}
                                </Typography>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    fontSize: `${fontSize - 2}px`,
                                    color: '#303f9f', // English in blue
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {page.english}
                                </Typography>
                              </motion.div>
                            ))}
                          </Box>
                        )}
                      </AnimatePresence>
                    </ContentBox>
                  </CardContent>
                </StyledCard>
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </Container>
  );
};

export default DevotionalContentPage;