// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAdiRhVCuTyGJWt36NwIkJWFt4Th5VSVF4",
  authDomain: "divineverse-app.firebaseapp.com",
  projectId: "divineverse-app",
  storageBucket: "divineverse-app.appspot.com",
  messagingSenderId: "307062855955",
  appId: "1:307062855955:web:43c41c1ba8783f10a122e6",
  measurementId: "G-2B0CWSYHBK"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };