// src/components/Footer.js

import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LinkedIn, WhatsApp, YouTube } from '@mui/icons-material';


function Footer() {
  return (
    <footer className="footer">
      <p>© {new Date().getFullYear()} BhaktiVerse. All rights reserved.</p>
      <div className="social-links">
        <a href="https://www.facebook.com/anitesh.shaw" aria-label="Facebook">
          <FaFacebookF />
        </a>
        <a href="https://www.linkedin.com/in/aniteshshaw/" aria-label="Instagram">
          <LinkedIn />
        </a>
        <a href="https://www.youtube.com/@MyBhaktiVerse" aria-label="Instagram">
          <YouTube />
        </a>
        <a href="https://chat.whatsapp.com/HK8CX1Y4eKgEiLlLhnuhER" aria-label="Instagram">
          <WhatsApp />
        </a>
        {/* <a href="https://www.linkedin.com/in/aniteshshaw/" aria-label="Instagram">
          <FaInstagram />
        </a> */}
        {/* <a href="#" aria-label="Twitter">
          <FaTwitter />
        </a> */}
      </div>
      <div className="footer-links">
                <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;