import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Link, Snackbar, Paper } from '@mui/material';
import { Facebook, LinkedIn, YouTube, WhatsApp, Email, Phone, LocationOn, Person } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';  // Make sure this path is correct

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save feedback to Firebase
      await addDoc(collection(db, 'feedback'), {
        ...formData,
        timestamp: new Date()
      });
      setSnackbar({
        open: true,
        message: 'Thank you for your feedback! We\'ll get back to you soon.',
        severity: 'success',
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSnackbar({
        open: true,
        message: 'There was an error submitting your feedback. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ borderRadius: 4, overflow: 'hidden' }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ bgcolor: '#4a148c', color: 'white', p: 4 }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 4 }}>
              Get in Touch
            </Typography>
            <Box sx={{ mb: 4 }}>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Person sx={{ mr: 2 }} /> Anitesh Shaw
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Email sx={{ mr: 2 }} /> mybhaktiverse@gmail.com
              </Typography>
              {/* <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Phone sx={{ mr: 2 }} /> +1 (123) 456-7890
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOn sx={{ mr: 2 }} /> 123 Bhakti Street, Spiritual City, Universe
              </Typography> */}
            </Box>
            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>Follow Us</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Link href="https://www.facebook.com/anitesh.shaw" target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                  <Facebook fontSize="large" />
                </Link>
                <Link href="https://www.linkedin.com/in/aniteshshaw/" target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                  <LinkedIn fontSize="large" />
                </Link>
                <Link href="https://www.youtube.com/@MyBhaktiVerse" target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                  <YouTube fontSize="large" />
                </Link>
                <Link href="https://chat.whatsapp.com/HK8CX1Y4eKgEiLlLhnuhER" target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                  <WhatsApp fontSize="large" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#4a148c' }}>
              Send Us a Message
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { mb: 3 } }}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
                variant="outlined"
              />
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  bgcolor: '#4a148c',
                  '&:hover': { bgcolor: '#7b1fa2' },
                  py: 1.5,
                  mt: 2,
                }}
              >
                Send Message
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactPage;