import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, ToggleButton, ToggleButtonGroup, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import LogoImage from '../assets/logo.png';

function Navbar() {
  const { i18n } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      i18n.changeLanguage(newLanguage);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const navLinks = [
    { text: 'Home', path: '/' },
    { text: 'Devotional Content', path: '/devotional-content' },
    { text: 'Puja Samagri', path: '/puja-samagri' },
    { text: 'Download APP', path: '/download' },
    { text: 'Contact US', path: '/contact' }, 
  ];

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navLinks.map((link, index) => (
          <ListItem 
            button 
            key={index} 
            component={Link} 
            to={link.path}
            selected={location.pathname === link.path}
          >
            <ListItemText primary={link.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <nav className="navbar">
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        className="menu-button"
      >
        <MenuIcon />
      </IconButton>
      
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>

      <div className="logo-title">
  <Link to="/" className="logo-link">
    <img src={LogoImage} alt="BhaktiVerse Logo" className="logo-image" />
  </Link>
  <h1 className="navbar-title">BhaktiVerse</h1>
</div>


      <Box className="language-toggle">
        <ToggleButtonGroup
          value={i18n.language}
          exclusive
          onChange={handleLanguageChange}
          aria-label="language"
        >
          <ToggleButton value="en" aria-label="english" className="toggle-btn">
            English
          </ToggleButton>
          <ToggleButton value="hi" aria-label="hindi" className="toggle-btn">
            हिन्दी
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </nav>
  );
}

export default Navbar;