// src/components/Features.js
import React from 'react';
import './Features.css'; // Create this file for styles

function Features() {
  return (
    <div className="features">
      <h2>Features</h2>
      <ul>
        <li>Jap Tracking</li>
        <li>Devotional Content</li>
        <li>Levels and Rewards</li>
        <li>Community Engagement</li>
      </ul>
    </div>
  );
}

export default Features;