import React, { useState, useEffect } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert } from '@mui/material';
import { collection, getDocs, addDoc, query, orderBy } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';

const ADMIN_EMAIL = 'mybhaktiverse@gmail.com';

const AdminFeedbackPage = () => {
  const [feedback, setFeedback] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === ADMIN_EMAIL) {
        setIsAdmin(true);
        fetchFeedback();
      } else {
        setIsAdmin(false);
        navigate('/admin/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchFeedback = async () => {
    const feedbackQuery = query(collection(db, 'feedback'), orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(feedbackQuery);
    setFeedback(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleReply = async () => {
    if (selectedFeedback && replyText) {
      try {
        await addDoc(collection(db, 'replies'), {
          feedbackId: selectedFeedback.id,
          reply: replyText,
          timestamp: new Date()
        });
        setDialogOpen(false);
        setReplyText('');
        // Optionally, you could refetch feedback or update the UI here
      } catch (error) {
        console.error('Error sending reply:', error);
      }
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/admin/login');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  if (loading) {
    return <Container maxWidth="md"><Typography>Loading...</Typography></Container>;
  }

  if (!isAdmin) {
    return (
      <Container maxWidth="md">
        <Alert severity="error">
          You are not authorized to view this page. Only mybhaktiverse@gmail.com can access the admin panel.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" sx={{ mb: 4 }}>Admin Feedback</Typography>
      <Button onClick={handleLogout} variant="outlined" sx={{ mb: 2 }}>Logout</Button>
      <List>
        {feedback.map((item) => (
          <ListItem key={item.id} divider>
            <ListItemText
              primary={item.name}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    {item.email}
                  </Typography>
                  {" — "}{item.message}
                </>
              }
            />
            <Button onClick={() => {
              setSelectedFeedback(item);
              setDialogOpen(true);
            }}>
              Reply
            </Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Reply to {selectedFeedback?.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Reply"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleReply}>Send Reply</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminFeedbackPage;