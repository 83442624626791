import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, setDoc, increment } from 'firebase/firestore';
import { db } from '../firebase-config';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
  Card,
  CardContent,
  CardMedia,
 
} from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Logo from '../assets/logo.png';
import { motion, animate } from 'framer-motion';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTranslation } from 'react-i18next';
import fontHindi from './NotoSansDevanagari-Regular-normal.js';
import { styled } from '@mui/system';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

const PujaSamagriPage = () => {
  const [pujas, setPujas] = useState([]);
  const [selectedPuja, setSelectedPuja] = useState(null);
  const [samagriList, setSamagriList] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const { t, i18n } = useTranslation();
  const [viewCount, setViewCount] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);
  // Checks if all items are selected
const isAllSelected = samagriList.length > 0 && samagriList.every(item => selectedItems[item.id] === true);

// Checks if some but not all items are selected (for indeterminate state)
const isIndeterminate = Object.values(selectedItems).some(v => v) && !isAllSelected;

  useEffect(() => {
    fetchPujas();
  }, []);

  const fetchPujas = async () => {
    const querySnapshot = await getDocs(collection(db, 'pujas'));
    const pujasData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPujas(pujasData);
  };
  // StyledCard component
const StyledCard = styled(Card)(({ theme }) => ({
    background: 'linear-gradient(145deg, #f3e5f5, #e1bee7)',
    borderRadius: '15px',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(4px)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
  }));

  const selectPuja = async (puja) => {
    setSelectedPuja(puja);
    const samagriRef = doc(db, 'samagri', puja.id);
    const samagriSnap = await getDoc(samagriRef);
    const pujaCountsRef = doc(db, 'pujaCounts', puja.id);
    const countsSnap = await getDoc(pujaCountsRef);

    if (samagriSnap.exists()) {
      const samagriData = samagriSnap.data().items || [];
      setSamagriList(samagriData);
      const initialSelected = {};
      samagriData.forEach(item => {
        initialSelected[item.id] = true;
      });
      setSelectedItems(initialSelected);
    }

    // Track puja view
    await setDoc(pujaCountsRef, { views: increment(1) }, { merge: true });

    if (countsSnap.exists()) {
      const countsData = countsSnap.data();
      setViewCount(countsData.views || 0);
      setDownloadCount(countsData.downloads || 0);
    }
  };
  
  const staggerAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.2, duration: 0.6, ease: "easeOut" }
    })
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      i18n.changeLanguage(newLanguage);
    }
  };

  const handleQuantityChange = (itemId, value) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [itemId]: value,
    }));
  };

  const handleCheckboxChange = (itemId, checked) => {
    setSelectedItems(prevSelectedItems => ({
      ...prevSelectedItems,
      [itemId]: checked,
    }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select all items
      const newSelectedItems = {};
      samagriList.forEach(item => {
        newSelectedItems[item.id] = true;
      });
      setSelectedItems(newSelectedItems);
    } else {
      // Uncheck all items
      setSelectedItems({});
    }
  };

  const generatePDF = async () => {
    const pdfDoc = new jsPDF();

    // Add custom Hindi font
    pdfDoc.addFileToVFS('NotoSansDevanagari-Regular.ttf', fontHindi);
    pdfDoc.addFont('NotoSansDevanagari-Regular.ttf', 'NotoSansDevanagari', 'normal');
    pdfDoc.setFont('NotoSansDevanagari');

    // Add logo and banner
    const pageWidth = pdfDoc.internal.pageSize.getWidth();
    pdfDoc.setFillColor(230, 230, 250); // Light lavender color
    pdfDoc.rect(0, 0, pageWidth, 30, 'F');
    if (Logo) {
      pdfDoc.addImage(Logo, 'PNG', 10, 5, 20, 20);
    }
    pdfDoc.setFontSize(20);
    pdfDoc.setTextColor(0, 0, 128);
    pdfDoc.text(selectedPuja.name[i18n.language], pageWidth / 2, 20, { align: 'center' });

    let currentY = 40;

    // History section
    if (selectedPuja.history) {
      pdfDoc.setFontSize(16);
      pdfDoc.setTextColor(0, 0, 128);
      pdfDoc.text(t('history_of_puja'), 14, currentY);
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor(0, 0, 0);
      currentY += 10;
      const historyText = pdfDoc.splitTextToSize(selectedPuja.history[i18n.language], pageWidth - 28);
      pdfDoc.text(historyText, 14, currentY);
      currentY += historyText.length * 5 + 10;
    }

    // Puja Vidhi section
    if (selectedPuja.bidhi) {
      pdfDoc.setFontSize(16);
      pdfDoc.setTextColor(0, 0, 128);
      pdfDoc.text(t('puja_vidhi_steps'), 14, currentY);
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor(0, 0, 0);
      currentY += 10;

      let bidhiSteps;
      if (Array.isArray(selectedPuja.bidhi[i18n.language])) {
        bidhiSteps = selectedPuja.bidhi[i18n.language];
      } else {
        bidhiSteps = selectedPuja.bidhi[i18n.language].split('\n');
      }

      bidhiSteps.forEach((step, index) => {
        const cleanedStep = step.trim().replace(/^\d+\.?\s*/, '');
        const stepText = pdfDoc.splitTextToSize(`${index + 1}. ${cleanedStep}`, pageWidth - 28);
        pdfDoc.text(stepText, 14, currentY);
        currentY += stepText.length * 5;
      });
      currentY += 10;
    }

    // Benefits section
    if (selectedPuja.benefits) {
      pdfDoc.setFontSize(16);
      pdfDoc.setTextColor(0, 0, 128);
      pdfDoc.text(t('benefits'), 14, currentY);
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor(0, 0, 0);
      currentY += 10;
      const benefitsText = pdfDoc.splitTextToSize(selectedPuja.benefits[i18n.language], pageWidth - 28);
      pdfDoc.text(benefitsText, 14, currentY);
      currentY += benefitsText.length * 5 + 10;
    }

    // Samagri Table
    const tableData = samagriList
      .filter(item => selectedItems[item.id])
      .map((item) => [
        item.name[i18n.language],
        quantities[item.id] || item.defaultQuantity,
        item.unit[i18n.language],
      ]);

    pdfDoc.autoTable({
      startY: currentY + 10,
      head: [[t('item_name'), t('quantity'), t('unit')]],
      body: tableData,
      theme: 'grid',
      headStyles: { font: 'NotoSansDevanagari', fontSize: 12, fillColor: [48, 25, 52], textColor: 255 },
      styles: { font: 'NotoSansDevanagari', fontSize: 12 },


      didDrawPage: (data) => {
        // Add watermark to the page
        const pageWidth = pdfDoc.internal.pageSize.getWidth();
const pageHeight = pdfDoc.internal.pageSize.getHeight();

pdfDoc.setFontSize(24);
pdfDoc.setTextColor(220, 220, 220);

for (let y = 50; y < pageHeight; y += 80) {
  for (let x = -50; x < pageWidth; x += 80) {
    pdfDoc.text([t('BhaktiVerse')], x, y, {
      angle: 45,
    });
  }
}

// Add footer
const footerY = pdfDoc.internal.pageSize.getHeight() - 20;
pdfDoc.setFontSize(10);
pdfDoc.setTextColor(150, 150, 150);
pdfDoc.text('© BhaktiVerse 2023', pageWidth / 2, footerY, { align: 'center' });
pdfDoc.text('Follow us on social media', pageWidth / 2, footerY + 5, { align: 'center' });
      },
    });

    
    // Save the PDF
    pdfDoc.save(`${selectedPuja.name[i18n.language]}_Samagri_List.pdf`);

    // Track PDF download
    const pujaCountsRef = doc(db, 'pujaCounts', selectedPuja.id);
    await setDoc(pujaCountsRef, { downloads: increment(1) }, { merge: true });
    setDownloadCount(prev => prev + 1);
    
  };

  // AnimatedNumber Component
  const AnimatedNumber = ({ value }) => {
    const [number, setNumber] = useState(0);

    useEffect(() => {
      const controls = animate(number, value, {
        duration: 1,
        onUpdate: (v) => setNumber(Math.floor(v)),
      });
      return () => controls.stop();
    }, [value, number]);

    return <motion.span>{number}</motion.span>;
  };

  return (
    <Container maxWidth="lg" className="page-content" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h2" gutterBottom align="center" sx={{ color: '#4a148c', fontWeight: 'bold' }}>
        {t('puja_samagri')}
        </Typography>

       
        
      </Box>

      {!selectedPuja ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Grid container spacing={3}>
                {pujas.map((puja, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={puja.id}>
                    <motion.div whileHover={{ scale: 1.05 }}>
                        <StyledCard onClick={() => selectPuja(puja)} sx={{ cursor: 'pointer' }}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={puja.imageUrl}
                            alt={puja.name[i18n.language]}
                            sx={{ objectFit: 'cover' }}
                        />
                        <CardContent>
                            <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>
                            {puja.name[i18n.language]}
                            </Typography>
                        </CardContent>
                        </StyledCard>
                    </motion.div>
                    </Grid>
                ))}
                </Grid>
        </motion.div>
      ) : (
        <div>
          <Button onClick={() => setSelectedPuja(null)} sx={{ mb: 1 }}>
            {t('back_to_pujas')}
          </Button>
          <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', mt: 2 }}>
            {selectedPuja.name[i18n.language]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <Typography variant="body1" sx={{ mx: 2 }}>
              {t('views')}: <AnimatedNumber value={viewCount} />
            </Typography>
            <Typography variant="body1" sx={{ mx: 2 }}>
              {t('downloads')}: <AnimatedNumber value={downloadCount} />
            </Typography>
          </Box>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {selectedPuja.imageUrl && (
                    <img
                      src={selectedPuja.imageUrl}
                      alt={selectedPuja.name[i18n.language]}
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '10px',
                        border: '5px solid #ddd',
                        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                      }}
                    />
                  )}
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
                {selectedPuja.history && (
                  <>
                    <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                      {t('history_of_puja')}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {selectedPuja.history[i18n.language]}
                    </Typography>
                  </>
                )}
                {selectedPuja.bidhi && (
                  <>
                    <Typography variant="h4" sx={{ mt: 4, color: 'primary.main', fontWeight: 'bold' }}>
                      {t('puja_vidhi_steps')}
                    </Typography>
                    {Array.isArray(selectedPuja.bidhi[i18n.language]) ? (
                      <ol>
                        {selectedPuja.bidhi[i18n.language].map((step, index) => (
                          <Typography key={index} component="li" variant="body1" sx={{ mt: 1 }}>
                            {step.trim().replace(/^\d+\.?\s*/, '')}
                          </Typography>
                        ))}
                      </ol>
                    ) : (
                      <ol>
                        {selectedPuja.bidhi[i18n.language].split('\n').map((step, index) => (
                          <Typography key={index} component="li" variant="body1" sx={{ mt: 1 }}>
                            {step.trim().replace(/^\d+\.?\s*/, '')}
                          </Typography>
                        ))}
                      </ol>
                    )}
                  </>
                )}
                {selectedPuja.benefits && (
                  <>
                    <Typography variant="h4" sx={{ mt: 4, color: 'primary.main', fontWeight: 'bold' }}>
                      {t('benefits')}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {selectedPuja.benefits[i18n.language]}
                    </Typography>
                  </>
                )}
              </motion.div>
            </Grid>
          </Grid>

          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.6 }}>
            <Typography variant="h4" sx={{ mt: 4, color: 'primary.main', fontWeight: 'bold' }}>
              {t('samagri_list')}
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#4a148c' }}>
                    <TableCell padding="checkbox">
                    <Checkbox
                    indeterminate={isIndeterminate} // This will display the indeterminate state when some but not all items are selected
                    checked={isAllSelected} // This will check the "Select All" checkbox when all items are selected
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all items' }}
                    />
                    </TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>{t('item_name')}</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>{t('quantity')}</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>{t('unit')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {samagriList.map((item, index) => (
                    <TableRow key={item.id} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems[item.id] || false}
                          onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                          inputProps={{ 'aria-label': `${t('select')} ${item.name[i18n.language]}` }}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      </TableCell>
                      <TableCell>{item.name[i18n.language]}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={quantities[item.id] || item.defaultQuantity}
                          onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                          variant="outlined"
                          size="small"
                          inputProps={{ min: 0, style: { textAlign: 'center' } }}
                          sx={{ width: '80px' }}
                        />
                      </TableCell>
                      <TableCell>{item.unit[i18n.language]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </motion.div>

          <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={generatePDF}>
            {t('generate_pdf')}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default PujaSamagriPage;