import React from 'react';
import './PrivacyPolicy.css'; // Link to the CSS file

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy for Bhakti Verse</h1>
            <p>Last Updated: "09/30/2024"</p>
            <p>
                Bhakti Verse ("we", "our", or "us") operates the Bhakti Verse mobile application (the "App"). This privacy policy explains how we collect, use, and protect user information in the App.
            </p>

            <div>
                <h2>1. Information We Collect</h2>
                <p><strong>Audio Recording:</strong> The App may request permission to access your device's microphone to record audio, which is used solely for counting Jap (chant repetitions) and tracking them. This feature helps users track their spiritual practice by recognizing and counting the chants made using the microphone. The audio is processed locally on your device and is not stored or shared with any third parties.</p>
                <p><strong>Device Information:</strong> We collect device-specific information, such as the type of mobile device you are using, operating system version, and unique device identifiers.</p>
                <p><strong>Location Data:</strong> If you grant us permission, we may collect and process location information from your device to provide personalized content.</p>
            </div>

            <div>
                <h2>2. How We Use the Information</h2>
                <ul>
                    <li>To provide and maintain the App.</li>
                    <li>To enhance and improve the App's features and performance.</li>
                    <li>To offer personalized content and services based on your location or preferences.</li>
                    <li>To analyze usage patterns for improving user experience.</li>
                </ul>
            </div>

            <div>
                <h2>3. Data Sharing</h2>
                <p>We do not share your personal information with third parties, except:</p>
                <ul>
                    <li>Third-Party Service Providers like [Google Analytics, Firebase] to analyze App usage and improve services.</li>
                    <li>Legal Requirements, if we believe such action is necessary to comply with legal obligations.</li>
                </ul>
            </div>

            <div>
                <h2>4. Data Storage and Security</h2>
                <p>We take reasonable measures to protect your data from unauthorized access or disclosure. However, no method of electronic storage is 100% secure.</p>
            </div>

            <div>
                <h2>5. Your Rights</h2>
                <ul>
                    <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
                    <li><strong>Correction:</strong> You can request corrections to any inaccuracies in your data.</li>
                    <li><strong>Deletion:</strong> You can request the deletion of your personal data.</li>
                </ul>
            </div>

            <div>
                <h2>6. Changes to This Privacy Policy</h2>
                <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>
            </div>

            <div>
                <h2>7. Contact Us</h2>
                <p>If you have any questions or concerns, contact us at:</p>
                <p>Email: "anitesh.shaw@gmail.com"</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;